import api from './api'

const findActive = () => {
  return api.get('articles/active')
}

const findById = (id) => api.get(`articles/find-by-id/${id}`)
const findBySlug = (slug) => api.get(`articles/find-by-slug/${slug}`)

const findActiveExternalArticles = () => api.get(`articles/active-external`);
const externalBySlug = (slug) => api.get(`articles/external-by-slug/${slug}`);

export { findActive, findById, findBySlug, findActiveExternalArticles, externalBySlug };
